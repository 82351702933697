/* global Component, _,__lodash__, uuidv4,  score */
/* eslint-disable no-unused-vars */
function titleCase(str) {
    try {
        return str.toLowerCase().split(' ').map(function (word) {
            if (word.length > 0)
                return word.replace(word[0], word[0].toUpperCase());
            return word;
        }).join(' ');
    }
    catch (e) {
        //console.log("Error en busqueda",e);
        return str;
    }
}

class headerSectionComponent extends Component {

    static name() {
        return "headerSectionComponent";
    }

    static componentName() {
        return "headerSectionComponent";
    }

    getProps() {
        return  ['renderMenu','renderSearch','headerTitle'];
    }

    data() {
        return {
            rapidMenu:false,
            baget_in_car: 0,
            optionList:[],
            show_modal: true,
            active_search:false,
            filterValue:'',
            flagforceUpdate:1,
            defaultHasBrandImg: false,
            defaultLogoUrl: '/static/ecommerce/img/app.png',
            isMobile: $(window).width() <= 991,
            //url:'/static/ecommerce/img/user.png'
        };
    }

    getWatch() {
        return {
            "$store.state.itemsFullLoader":function (newValue,oldValue){
                if(newValue) {
                    this.flagforceUpdate = this.flagforceUpdate + 1;
                    this.launchFilter();
                    this.reloadOption();
                    this.loadPrices();
                }
            },
            "carItemBadge":function (){
                this.loadPrices();
            }
        };
    }

    mounted() {
        return function () {
            this.$nextTick(function () {
                this.launchFilter();
            });

            this.subscribeEvent('closeSandwichMenu', this.closeSandwichMenu);
        };
    }

    updated() {
        return function () {
            this.loadPrices();

        };
    }

    getComputed() {
        return {
            HomeTopMessage: function (){
                if(this.$store.getters.getSetting){
                    return this.$store.getters.getSetting.HomeTopMessage;
                }
                return null;
            },
            logoUrl: function (){
                if (this.$store.getters.getSetting.DemoMode) {
                    return '/static/ecommerce/img/oppen_bl.png';
                }
                if(this.$store.getters.getSetting.UseMenuImage){
                    if(this.$store.getters.getSetting.MenuImage)
                        return './../assets/' + this.$store.getters.getSetting.MenuImage;
                    else
                        return './../assets/LOGO';
                }
                return this.defaultLogoUrl;
            },
            carAmount:function (){
                let amountInCard = this.$store.getters.getCarAmount;
                if(amountInCard) {
                    amountInCard = amountInCard.toFixed(2);
                    return this.metricNumber(amountInCard);
                }
                return 0;
            },
            hasBrandImg:function (){
                let settings = this.$store.getters.getSetting;
                if(settings)
                    return settings.UseMenuImage;
                return this.defaultHasBrandImg;
            },
            hideIcons:function (){
                let settings = this.$store.getters.getSetting;
                if(settings)
                    if(settings.HideIcons && settings.DisableLoginAction)
                        return true;
                return false;
            },
            session: function () {
                if(this.$store.getters.getSession && this.flagforceUpdate > 0)
                    return true;
                else
                    return false;
            },
            menuVisible: function (){
                if(this.renderMenu == undefined || this.renderMenu == null)
                    return true;
                else
                    return this.renderMenu;
            },
            inputSearch: function (){
                if(this.renderSearch == undefined || this.renderSearch == null)
                    return true;
                else
                    return this.renderSearch;
            },
            menuTitle:function (){
                return this.$store.getters.menuTitle;
            },
            menuSubTitle:function (){
                return this.$store.getters.menuSubTitle;
            },
            mustRender:function (){
                return this.$store.getters.settingsIsLoaded;
            },
            carItemBadge:function () {
                let self = this;
                if(this.$store.getters.getSetting && this.$store.getters.getSetting.ShowTotalQty == 1)
                    return  _.sumBy(self.$store.getters.getItemCar, function (item) {
                        return parseInt(item.cant);
                    });
                else
                    return this.$store.getters.getItemCar.length;
            },
            showRapidMenu:function (){
                return this.optionList.length > 0 && this.rapidMenu;
            },
            avatarUrl: function () {
                if(this.$store.getters.getAvatar)
                    return this.$store.getters.getAvatar;
                else return "../static/ecommerce/img/user.png";
            },
        };
    }

    getMethods() {
        return {

            toogleSearch:this.toogleSearch,
            logout: this.logout,
            openModalLogin: this.openModalLogin,
            openModalRegister: this.openModalRegister,
            openModalChangePassword: this.openModalChangePassword,
            openModalCustomer:this.openModalCustomer,
            openReport:this.openReport,
            loadPrices:this.loadPrices,
            loadPricesSuggestions: this.loadPricesSuggestions,
            delayReload:this.delayReload,
            reloadOption:this.reloadOption,
            filter:this.filter,
            updateCtyValue:this.updateCtyValue,
            autoResetBlur:this.autoResetBlur,
            launchFilter:this.launchFilter,
            maxItemInCar:this.maxItemInCar,
            addToCar:this.addToCar,
            qtyDecrement:this.qtyDecrement,
            qtyIncrement:this.qtyIncrement,
            closeSandwichMenu:this.closeSandwichMenu
        };
    }

    toogleSearch(){
        this.active_search = !this.active_search;
    }

    logout() {
        this.$store.dispatch('logout');
        this.$router.push('/home');
    }

    openModalLogin() {
        $('#loginModal').modal();
        $('#loginModal').modal('open');
    }

    openModalRegister() {
        $('#registerModal').modal();
        $('#loginModal').modal('close');
        $('#registerModal').modal('open');
    }

    openModalChangePassword() {
        $('#changePasswordModal').modal();
        $('#changePasswordModal').modal('open');
    }

    openModalCustomer() {
        $('#registerCustomerModal').modal();
        $('#registerCustomerModal').modal('open');
    }

    openReport(reportName){
        if(reportName)
            this.$router.push('/report/' + reportName);
    }

    loadPrices(){
        let itemCodes = new Map();
        let items = this.$store.getters.getItemCar;
        for(let item of items) {
            if (!itemCodes.has(item.item.Code)) {
                let tempItem = this.$store.getters.getItemByCode(item.item.Code);
                if (tempItem && !tempItem.isCalculate)
                    itemCodes.set(item.item.Code, tempItem);
            }
        }
        if(itemCodes.size > 0) {
            let self = this;
            this.$store.dispatch("updatePrices", Array.from(itemCodes.values())).then(function (response) {
                self.$store.commit('updateCarAmount',self.$store.getters);
            });
        }
    }

    async loadPricesSuggestions(optionList){
        if(optionList.length > 0) {
            let itemCodes = new Map();
            for (let item of optionList.slice(0,10)) {
                if (!itemCodes.has(item.Code)) {
                    if (!item.isCalculate)
                        itemCodes.set(item.Code, item);
                }
            }
            let self = this;
            if (itemCodes.size > 0) {
                this.$store.dispatch("updatePrices", Array.from(itemCodes.values())).then((response) =>{
                    if(!self.$store.getters.showZeroPrice){
                        self.optionList = optionList.filter((x)=>{
                            return self.$store.getters.showItemPrice(x) > 0;
                        }).slice(0,10);
                    } else {
                        self.optionList = optionList.slice(0,10);
                    }
                });
            } else {
                if(!this.$store.getters.showZeroPrice){
                    optionList = optionList.filter((x)=>{
                        return self.$store.getters.showItemPrice(x) > 0;
                    }).slice(0,10);
                    this.optionList = optionList;
                } else {
                    this.optionList = optionList.slice(0,10);
                }
            }
        }
    }

    delayReload(event){
        if(this._setTimeoutReload){
            clearTimeout(this._setTimeoutReload);
        }
        this._setTimeoutReload = setTimeout(this.reloadOption,550);
    }

    reloadOption(event) {
        this.reloadingOptions = true;
        this._setTimeoutReload = null;
        let filterValue = this.filterValue.trim();
        let synonym = this.$store.getters.trSynonyms(filterValue.toString());
        if(!filterValue || filterValue.length)
            this.optionList = [];
        if(filterValue.length > 1){
            let newoptionList = [];
            for (let item of this.$store.state.items_store.values()) {
                if(this.$store.state.useVariations && item.Father)
                    continue;
                item.SearchScore = 0;
                let isVisible = false;
                try {
                    if (synonym) {
                        item.SearchScore = score(item.Name.toLowerCase(),synonym.toLowerCase());
                        if (item.SearchScore >= this.$store.state.scoringAceptation) {
                            item.carCant = 1;
                            isVisible = true;
                        }
                        if(!isVisible && item.Comment){
                            item.SearchScore = score(item.Comment.toLowerCase(),synonym.toLowerCase());
                            if (item.SearchScore >= this.$store.state.scoringAceptation) {
                                item.carCant = 1;
                                isVisible = true;
                            }
                        }
                    }
                    let tempScoring = score(item.Name.toLowerCase(),filterValue.toLowerCase());
                    item.SearchScore = Math.max(item.SearchScore,tempScoring);
                    if (!isVisible && tempScoring >= this.$store.state.scoringAceptation) {
                        item.carCant = 1;
                        isVisible = true;
                    }
                    if(!isVisible && item.Comment) {
                        item.SearchScore = score(item.Comment.toLowerCase(), filterValue.toLowerCase());
                        if (item.SearchScore >= this.$store.state.scoringAceptation) {
                            item.carCant = 1;
                            isVisible = true;
                        }
                    }
                    if(isVisible)
                        newoptionList.push(item);
                }
                catch (e) {
                    //console.log('Error in item',item,e);
                    continue;
                }
            }
            newoptionList = __lodash__.orderBy(newoptionList,'SearchScore',"desc");
            if(newoptionList.length > 0) {
                this.loadPricesSuggestions(newoptionList);
            }

            this.reloadingOptions = false;
        }
    }

    filter(){
        this.optionList = [];
        let searchWords = this.filterValue.toString().toLowerCase().trim();
        let filter = {};
        let synonym = this.$store.getters.trSynonyms(this.filterValue.toString());
        filter.name = 'inputSearch-' + uuidv4();
        filter.type = `inputSearch`;
        filter.filterValue = this.filterValue.toString();
        let settings = this.$store.getters.getSetting;
        filter.condition = "";
        if(settings.SearchFields && settings.SearchFields.split(",").length > 0){
            let filterFields = settings.SearchFields.split(",");
            let isFirts = true;
            let itemTemplate = this.$store.state.items_store.values().next().value;
            let regSearchWords = searchWords.replace(/\//,".*");
            //console.log("ASDADADADADADAD",regSearchWords);
            for(let filterField of filterFields){
                if(itemTemplate.hasOwnProperty(filterField)) {
                    if (!isFirts) {
                        filter.condition += " || ";
                    }
                    if(['Name',"Comment"].indexOf(filterField) != -1) {
                        filter.condition += ` ( x.${filterField} && ( score(x.${filterField}.toLowerCase(),'${searchWords}')>=${this.$store.state.scoringAceptation} `;
                        if (synonym) {
                            filter.condition += ` || (x.${filterField}.toLowerCase(),'${synonym}')>=${this.$store.state.scoringAceptation}) `;
                        }
                    }
                    else{

                        filter.condition += ` ( x.${filterField} && ( x.${filterField}.toString().toLowerCase().search(/${regSearchWords}/) !=-1 `;
                        if (synonym) {
                            filter.condition += ` || ( x.${filterField} && ( x.${filterField}.toString().toLowerCase().search(/${regSearchWords}/)!=-1 `;
                        }
                    }
                    filter.condition += ` )) `;
                    let filterFormat = filterField + "_format";
                    if(itemTemplate.hasOwnProperty(filterFormat)) {
                        if(['Name',"Comment"].indexOf(filterField) != -1) {
                            filter.condition += `  || ( x.${filterFormat} && ( score(x.${filterFormat}.toLowerCase(),'${searchWords}')>=${this.$store.state.scoringAceptation} `;
                            if (synonym) {
                                filter.condition += ` || (x.${filterFormat}.toLowerCase(),'${synonym}')>=${this.$store.state.scoringAceptation} )  `;
                            }
                        }
                        else{
                            filter.condition += `  || ( x.${filterFormat} && ( x.${filterFormat}.toString().toLowerCase().search(/${regSearchWords}/) !=-1 `;
                            if (synonym) {
                                filter.condition += ` || ( x.${filterFormat} && ( x.${filterFormat}.toString().toLowerCase().search(/${regSearchWords}/)!=-1 `;
                            }
                        }
                        filter.condition += ` )) `;
                    }
                    isFirts = false;
                }
            }
        }
        else {
            if (synonym)
                filter.condition = `score(x.Name.toLowerCase(),'${searchWords}')>=${this.$store.state.scoringAceptation} || x.Code=='${searchWords}' || score(x.Name.toLowerCase(),'${synonym}')>=${this.$store.state.scoringAceptation}`;
            else
                filter.condition = `score(x.Name.toLowerCase(),'${searchWords}')>=${this.$store.state.scoringAceptation}`;
        }
        filter.synonym = synonym;
        filter.result = true;
        this.$store.dispatch('addGlobalFilter',filter);
        this.$store.dispatch('updateCategory',null);
        
        if((this.$route.query.search && this.$route.query.search != this.filterValue) || !this.$route.query.search)
            this.$router.push({path: '/products', query:{search:this.filterValue}});

        if (this.isMobile) {
            this.emitEvent('closeSandwichMenu');
        }

        this.filterValue = '';
    }

    updateCtyValue(event,opt){
        if(this._resetBlurInterval)
            clearInterval(this._resetBlurInterval); 

        if(parseInt(event.target.value) + this.$store.getters.getItemQtyCar(opt.Code) <= this.$store.getters.getMaxQtyItem) {
            opt.carQty = event.target.value;
            return false;
        }
        else {
            event.target.value = 1;
            opt.carQty = 1;
            this.$store.dispatch('showNotificacion', {
                title: this.tr(`Qty`),
                message: this.tr("Maximum item quantity reached"),
                type: 'error'
            });
        }
 
        return true;
    }

    launchFilter(){
        let settings = this.$store.getters.getSetting;
        if(!settings || this.$store.getters.AppStatus) {
            setTimeout(this.launchFilter,1000);
        }
        else{
            if(this.$route.query.search != undefined) {
                this.filterValue = this.$route.query.search;
                this.filter();
            }
        }
    }

    maxItemInCar(item){
        if(this.$store.getters.getControlStock)
            return item.Qty;
        return this.$store.getters.getMaxQtyItem - this.$store.getters.getItemQtyCar(item.Code);
    }

    addToCar(item){
        if(this.maxItemInCar(item) < 1){
            item.carQty = 1;
            this.$store.dispatch('showNotificacion',{title:this.tr(`Qty`), message:this.tr("Maximum item quantity reached"),type: 'error'});
            return;
        }
        if(isNaN(item.carQty) || item.carQty <= 0) {
            this.$store.dispatch('showNotificacion',{title:this.tr(`Qty`), message:this.tr("Incorrent Qty Value"),type: 'error'});
            return;
        }
        this.$store.dispatch('ItemComponentAddToCar',item);
        item.carQty = 1;
    }

    qtyDecrement(item){
        if(isNaN(item.carQty)) {
            item.carQty = 0;
        }
        if(item.carQty > 1)
            item.carQty = parseInt(item.carQty) - 1;
        else
            item.carQty = 1;
    }

    qtyIncrement(item) {
        if (isNaN(item.carQty)) {
            item.carQty = 0;
        }
        if(item.carQty + 1 <= this.maxItemInCar(item))
            item.carQty = parseInt(item.carQty) + 1;
    }

    closeSandwichMenu() {
        if (this.isMobile) {
            $(this.$el).find('#navbarSupportedContent').collapse('hide');
        }
    }

    autoResetBlur() {
        this._resetBlurInterval = setInterval(()=>document.activeElement.blur(), 4000);
    }

    getTemplate() {
        return `<div>
                <div v-if="HomeTopMessage" class="top-bar text-center text-uppercase p-2"><p>{{HomeTopMessage}}</p></div>
                <nav class="navbar navbar-expand-lg navbar-light" v-if="mustRender">
                  <router-link to="/home" class="nav-link brand-link" href="#">
                      <template v-if="hasBrandImg">
                          <img class="brand-logo"  :src="logoUrl">
                      </template>
                      <template v-if="!hasBrandImg">
                          <span class="brand-logo">{{menuTitle}}</span>
                          <span class="app-name">{{menuSubTitle}}</span>
                      </template>
                  </router-link>
                  <ul class="navbar-nav d-sm-block d-lg-none ml-auto">
                      <li class="nav-item cart-icon-container" :title="tr(['Total Amount in Car $ {{$1}}', carAmount])">
                            <router-link to="/badges" class="nav-link cart-icon pt-0">
                                <i class="fa fa-shopping-cart fa-2x" ></i>
                                <span class="cart-badge badge badge-light">
                                        {{metricNumber(parseInt(carItemBadge))}}
                                    </span>
                            </router-link>
                            <span class="total" v-if="$store.getters.getSetting.ShowTotalPrice">$ {{carAmount}}</span>
                      </li>
                  </ul>
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"><i class="icon fas fa-bars"></i></span>
                  </button>

                  <div class="collapse navbar-collapse" id="navbarSupportedContent" >
                    <div class="search-wrapper form-inline mt-3 mt-lg-0 col-md-6 p-0" autocomplete="off"  @mouseenter="rapidMenu=true" >
                            <input list="searchinput" class="inputSEARCH search-input form-control mr-sm-2" type="search" v-model="filterValue" @change2="filter" @keydown="delayReload" @keydown.enter="filter" aria-label="Search" autocomplete="none" :placeholder="tr('ECOMSEARCHTEXT')">
                            <span class="search-icon" @click="filter"><i class="fas fa-search"></i></span>
                            <div class="header_datalist container pl-1 pl-sm-3 pr-1 pt-3" v-if="showRapidMenu" @mouseleave="rapidMenu=false">
                                <div class="categorias d-none" >
                                </div>
                                <!--h6 class="font-weight-bold my-3">Productos Sugeridos</h6-->
                                <div class="products-scroll products-scroll--alt" >
                                    <template v-for="opt in optionList">
                                        <div class="product-small product-small--alt py-2 mr-1">
                                            <div class="row">
                                                <div class="product__image col-3 col-md-2 px-2">
                                                    <div class="item-detail-modal" >
                                                        <itemSliderComponent :key="'slide'+opt.Code" :item="opt" :stockFlag="$store.getters.showStock" :container="false"  :zoom="false"></itemSliderComponent>
                                                    </div>
                                                </div>
                                                <div class="product__content col-9 col-md-10 px-2 align-self-center">
                                                    <h6 class="col-12 pl-1">
                                                        <router-link :to="'/view/'+encodeToURL(opt.Code)">{{opt.Name}}</router-link>
                                                    </h6>
                                                    <div class="col-12 pl-1">
                                                        <div class="row align-items-center">
                                                            <div class="price col-12 col-sm-4 col-md-4"  v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                <strong class="d-block crossedoutprice" v-if="opt.CrossedOutPrice">
                                                                    $ {{opt.CrossedOutPrice}}
                                                                </strong>
                                                                $ {{$store.getters.showItemPrice(opt)}}
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <div class="input-group add-to-cart-buttons">
                                                                    <div class="input-group-prepend" @click="qtyDecrement(opt)">
                                                                        <span class="input-group-text button" id="validationTooltipUsernamePrepend">
                                                                             <i class="icon fas fa-minus"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input :key="'th-input-'+opt.Code" type="text" class="form-control" :value="opt.carQty" :max="maxItemInCar(opt)" :disabled="maxItemInCar(opt)<1" @change="(event)=>updateCtyValue(event,opt)" @onfocus="autoResetBlur"  @focusout="(event)=>updateCtyValue(event,opt)" >
                                                                    <div class="input-group-append" @click="qtyIncrement(opt)">
                                                                        <span class="input-group-text button" id="validationTooltipUsernamePrepend">
                                                                            <i class="icon fas fa-plus"></i>
                                                                        </span>
                                                                    </div>
                                                                    <div class="input-group-append" @click.stop="addToCar(opt)">
                                                                        <span class="cart-button input-group-text button ml-2 p-2" id="validationTooltipUsernamePrepend">
                                                                             <i class="icon fas fa-cart-plus"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div class="col-12 text-center mt-2 mb-2" v-if="filterValue">
                                    <div @click="filter" class="btn-link" >Ver todos los productos</div>
                                </div>
                            </div>
                        </div>
                    <ul v-if="!hideIcons" class="navbar-nav flex-row ml-md-auto d-md-flex justify-content-end">
                        <li class="nav-item" v-if="session" :title="tr('Favorities')">
                            <router-link to="/favorities" class="nav-link">
                                <i class="fa fa-heart fa-2x" ></i>
                            </router-link>
                        </li>
                        <li class="nav-item d-none d-lg-block cart-icon-container" :title="tr(['Total Amount in Car $ {{$1}}', carAmount])">
                            <router-link to="/badges" class="nav-link cart-icon">
                                <i class="fa fa-shopping-cart fa-2x" ></i>
                                <span class="cart-badge badge badge-light">
                                    {{metricNumber(parseInt(carItemBadge))}}
                                </span>
                            </router-link>
                            <span class="total" v-if="$store.getters.getSetting.ShowTotalPrice">
                                 <template v-if="Number.isNaN(Number.parseInt(carAmount))">
                                    $ ...
                                 </template>
                                 <template v-else>
                                    $ {{carAmount}}
                                 </template>
                             </span>
                        </li>
                        <li class="nav-item">
                            <template  v-if="session">
                                <router-link key="profile-link" to="/profile" :title="tr('Profile')" class="nav-link">
                                   <img key='avatar ' :src="avatarUrl" alt="" width="45" height="45" class="avatar img-fluid rounded-circle customer-image responsive-img">
                                </router-link>
                            </template>
                            <template v-else>
                                <router-link key="login-link" to="/login" :title="tr('Login')" class="nav-link">
                                    <i class="fa fa-user fa-2x" ></i>
                                </router-link>
                            </template>
                        </li>
                    </ul>
                  </div>
                </nav>
                <subheaderSectionComponent></subheaderSectionComponent>
              </div>`;
    }
}

headerSectionComponent.registerComponent();
